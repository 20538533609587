import { render, staticRenderFns } from "./_.vue?vue&type=template&id=3e964311&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductPage: require('/~/src/components/ProductPage.vue').default,LayeredNavigation: require('/~/src/modules/opus-ecom/components/LayeredNavigation.vue').default,ContentfulWidgetList: require('/~/src/modules/contentful/components/widget-list.vue').default,ContentfulBlogPage: require('/~/src/modules/contentful/components/Widget/blog-page.vue').default})
