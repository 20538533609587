//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['imageUrl', 'imageAlt', 'title', 'description', 'createdAt', 'ingress', 'filetype', 'imageDescription'],
};
