//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: [],
    data() {
        return {
            url: '',
        };
    },
    mounted() {
        if (process.client) {
            this.url = window.location;
        }
    },
    methods: {},
};
