//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['product'],
    fetch() {
        if (!this.product) return;
        const categoriesNonEmpty = this.product.categories.filter((category) => category.breadcrumbs).map((category) => category.breadcrumbs);
        const categoriesList = [];
        categoriesNonEmpty.forEach((breadcrumbs) =>
            categoriesList.push(...breadcrumbs.filter((breadcrumb) => breadcrumb.category_name).map((breadcrumb) => breadcrumb.category_name)),
        );

        this.categoriesList = Array.from(new Set(categoriesList)).map((category) => `/${category}`);

        this.productUrl = `${this.$config.HOSTNAME}/${this.product.url_key}`;
    },
    data() {
        return {
            categoriesList: [],
            productUrl: '',
        };
    },
};
