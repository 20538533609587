import { render, staticRenderFns } from "./CrossProducts.vue?vue&type=template&id=1e6e3158&"
import script from "./CrossProducts.vue?vue&type=script&lang=js&"
export * from "./CrossProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default,OpusCard: require('/~/src/modules/opus/components/Card.vue').default})
