//
//
//
//
//
//
//
//
//

export default {
    props: {
        sku: {
            type: String,
            default: '',
            required: true,
        },
        label: {
            type: [String, undefined],
            default: undefined,
            required: false,
        },
    },
    data() {
        return {
            max: 4,
            map: {
                Kevyt: 1,
                Hyvä: 2,
                Erinomainen: 3,
                Extreme: 4,
            },
        };
    },
    computed: {
        left() {
            return this.max - this.level;
        },
        level() {
            return this.label && this.map[this.label] ? this.map[this.label] : null;
        },
    },
};
