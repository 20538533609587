//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['createdAt'],
    data() {
        return {
            page: null,
            prev: null,
            next: null,
        };
    },
    methods: {
        copyLink() {
            try {
                window.navigator.clipboard.writeText(window.location.href);
                this.$notify.success('Linkki kopioitu leikepöydälle!');
            } catch (e) {
                this.$notify.error(e);
            }
        },
    },
    async fetch() {
        const next = await this.$contentful.getEntries({
            content_type: 'blogPage',
            locale: 'fi',
            include: 4,
            limit: 1,
            'sys.createdAt[gt]': this.createdAt,
        });
        const prev = await this.$contentful.getEntries({
            content_type: 'blogPage',
            locale: 'fi',
            include: 4,
            limit: 1,
            'sys.createdAt[lt]': this.createdAt,
        });
        if (next.total) {
            this.next = next.items[0];
        }
        if (prev.total) {
            this.prev = prev.items[0];
        }
    },
};
