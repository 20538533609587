//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['product'],
    data() {
        return {
            maxStars: 5, // TODO: remake in centralized manner?
        };
    },
    computed: {
        starCount() {
            return parseInt(((this.percentValue / 100) * this.maxStars).toFixed(0)) || 0;
        },
        ratingPercentValue() {
            // TODO: move to helper function
            const len = [...this.product.reviews.items].length;
            const total = [...this.product.reviews.items].reduce((acc, curr) => {
                const rating = curr.average_rating;
                acc += rating;

                return acc;
            }, 0);
            return parseInt(total / len) || 0;
        },
    },
    methods: {
        show() {
            this.$scrollTo('#reviews-section');
            this.$root.$emit('reviewOpen');
        },
    },
};
