
let lastComponent = null;
export default {
    props: {
        widgets: {
            type: Array,
            required: true,
        },
    },
    methods: {
        /**
         * @param {Function} createElement
         * @param {Object} widget
         * @returns {Object}
         */
        getWidgetComponent(createElement, widget) {
            let padding = true;
            if (!widget.sys.contentType) {
                /* Content type has been deleted, but references still exists */
                return;
            }
            if (lastComponent === widget.sys.contentType.sys.id) {
                padding = false;
            }
            lastComponent = widget.sys.contentType.sys.id;

            return createElement(this.widgetTagName(widget.sys.contentType.sys.id), {
                props: {
                    widget,
                    padding,
                },
            });
        },

        /**
         * @param {String} type - ex. 'content_full'
         * @returns {string} - ex. 'content-full'
         */
        widgetTagName(type) {
            return `contentful-${type}`;
            const dash = type.replace(/_/gi, '-');

            return `${dash.split('.').pop()}`;
        },
    },
    render(createElement) {
        const widgetComponents = this.widgets.map((widget) => this.getWidgetComponent(createElement, widget));

        return createElement(
            'main',
            {
                attrs: {
                    class: 'widget-list',
                },
            },
            widgetComponents,
        );
    },
};
