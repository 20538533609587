import { render, staticRenderFns } from "./BlogFooter.vue?vue&type=template&id=7fe76b56&"
import script from "./BlogFooter.vue?vue&type=script&lang=js&"
export * from "./BlogFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusIcon: require('/~/src/modules/opus/components/Icon.vue').default,OpusLink: require('/~/src/modules/opus/components/Link.vue').default,OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default,SocialShareButtons: require('/~/src/components/SocialShareButtons.vue').default})
