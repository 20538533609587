//
//
//
//
//

export default {
    props: ['product'],
    async fetch() {
        try {
            const productType = this.product.custom_attributes.find((attribute) => attribute['attribute_metadata'].code === 'detailed_product_type');
            const label = productType ? productType['selected_attribute_options']['attribute_option'][0].label : '';

            const locale = 'fi';

            if (!label.length) {
                return;
            }

            const page = await this.$axios.$get(`/productinfo/${locale}/${label}`);

            if (page.total) {
                this.page = page.items[0];
            }
        } catch (e) {
            console.error(e);
        }
    },
    data() {
        return {
            page: {},
        };
    },
};
