//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['product'],
    data() {
        return {};
    },
    computed: {},
    methods: {},
};
