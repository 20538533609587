//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['product'],
    data() {
        return {
            qty: 1,
            added: [],
            showAllProducts: false,
            maxProducts: 4,
        };
    },
    methods: {
        showAll() {
            this.showAllProducts = true;
        },
        async submit(item, e) {
            e.preventDefault();
            const payload = {
                sku: item.sku,
                quantity: this.qty,
            };
            this.errors = [];
            const { cart, user_errors } = await this.$store.dispatch('cart/addProduct', {
                items: [payload],
                product: item,
            });
            this.errors = user_errors;
            if (!this.errors.length) {
                this.added.push(item.sku);
                this.$notify.success(this.$t('catalog.crossell.add.notification'));
            }
        },
    },
};
