export default {
    data() {
        return {
            head: {
                link: this.prepareCanonicalLinkTag(),
            },
        };
    },
    methods: {
        prepareCanonicalLinkTag() {
            const url = this.$route.path !== '/' ? this.$config.HOSTNAME + this.$route.path : this.$config.HOSTNAME;

            return [
                {
                    type: 'link',
                    rel: 'canonical',
                    href: url,
                },
            ];
        },
    },
};
