import { render, staticRenderFns } from "./blog-page.vue?vue&type=template&id=6e27ac64&"
import script from "./blog-page.vue?vue&type=script&lang=js&"
export * from "./blog-page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulBlogHeader: require('/~/src/modules/contentful/components/Blog/BlogHeader.vue').default,ContentfulWidgetList: require('/~/src/modules/contentful/components/widget-list.vue').default,ContentfulBlogFooter: require('/~/src/modules/contentful/components/Blog/BlogFooter.vue').default})
